
import { computed, defineComponent, onMounted, ref } from "vue";
import ExclamationNotice from "@/components/my-components/notices/exclamation-notice.vue";

export default defineComponent({
  name: "no_money_request",
  props: {
    user_no_money_request: null,
  },
  components: {
    ExclamationNotice,
  },
});
